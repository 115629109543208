
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonButton, IonText, IonItem, IonLabel, IonSpinner, IonBackButton, IonSelect, IonTextarea, toastController,
IonSelectOption, IonButtons  } from '@ionic/vue';
import { defineComponent } from 'vue';
import { API_URL } from '@/providers/constants';
import axios from "axios";
import { Storage } from "@capacitor/storage";

export default defineComponent({
  name: 'Login',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonInput,
    IonButton,
    IonText,
    IonItem,
    IonLabel,
    IonSpinner,
    IonBackButton,
    IonSelect,
    IonTextarea,
    IonSelectOption,
    IonButtons
  },
  data() {
    return {
      username: '',
      password: '',
      responseStatus: '',
      loading: false,
      categoriesUrl: '',
      productUrl: '',
      productSku: '',
      productName: '',
      productDescription: '',
      productPrice: '',
      productCategories: '',
      productBrand: '',
      categories: {},
      token: "",
    }
  },
  mounted() {
    this.getToken()
    setTimeout(() => {
      this.fetchCategories()
    }, 500)
  },
  methods: {
      createProduct() {
          this.loading = true;

            this.productUrl = API_URL + '/rest/V1/products';

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token 
            }

            const inputCategories = this.productCategories;

            const productCategories = [];

            inputCategories.forEach(category => {
              productCategories.push(category);
            });

            const productData = {
                "product" :{
                    "sku" : this.productSku,
                    "name": this.productName,
                    "status": 1,
                    "visibility": 1,
                    "type_id": "simple",
                    "price": this.productPrice,
                    "attribute_set_id": 4,
                    "weight": "1",
                    "custom_attributes": [
                        {
                            "attribute_code": "description",
                            "value": this.productDescription
                        },
                        {
                            "attribute_code": "category_ids",
                            "value": productCategories
                        },
                        {
                            "attribute_code": "fb_product_gender",
                            "value": "Unisex"
                        },
                        {
                            "attribute_code": "google_product_category",
                            "value": "2899"
                        },
                        {
                            "attribute_code": "fb_product_brand",
                            "value": this.productBrand
                        }
                    ],
                    "extension_attributes": {
                        "stock_item": {
                            "qty": "1",
                            "is_in_stock": true
                        }
                    }
                }
            }

            axios.post(this.productUrl, productData, { headers })
            .then(response => {
                console.log(response.data);
                if(response.data) {
                    console.log(response)
                }
                else
                console.log(response)
                    this.openToast("There was an error creating product, please try again");
                this.loading = false;
            }).catch(error => {
                if(error.response)
                    this.openToast('Session timeout, please exit the app and login again');
                console.log(error.response)
                this.loading = false;
            })
      },
      async openToast(message) {
      const toast = await toastController
        .create({
          message: message,
          duration: 2000
        })
      return toast.present();
      },
      fetchCategories() {
            this.loading = true;

            this.categoriesUrl = API_URL + '/rest/V1/categories/list?searchCriteria[page_size]=1000';

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.token 
            }

            axios.get(this.categoriesUrl, { headers })
            .then(response => {
                if(response.data.items.length)
                    this.categories = response.data.items;
                else
                    this.openToast("No items with name or SKU were found");
                this.loading = false;
            }).catch(error => {
                if(error.response)
                    this.openToast('Session timeout, please exit the app and login again');
                this.loading = false;
            })
      },
      async getToken() {
        const { value } = await Storage.get({ key: "token" });
        this.token = value;
      },
  }
});
